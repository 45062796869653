import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  trackFb(event, data) {
    ReactPixel.track(event, data);
  },
  trackPageViewBypixel(pixel) {
    ReactPixel.init(pixel);
    ReactPixel.pageView();
  },
  trackPageViewCustomBypixel(pixel, description) {
    ReactPixel.init(pixel);
    ReactPixel.trackSingle(pixel, "PageView", {
      description: description,
    });
  },
  trackFbCustom(event, data) {
    ReactPixel.trackCustom(event, data);
  },
  trackSingle(pixel, event, data) {
    ReactPixel.init(pixel);
    ReactPixel.trackSingle(pixel, event, data);
  },
  trackSingleCustom(pixel, event, data) {
    ReactPixel.init(pixel);
    ReactPixel.trackSingleCustom(pixel, event, data);
  },
  trackGa(category, action, label) {
    ReactGA.event({
      category,
      action,
      label,
    });
  },
};
